import React from "react";
import theme from "theme";
import { Theme, Link, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Чистий Дім - Професійний клінінг сервіс
			</title>
			<meta name={"description"} content={"Професійний клінінг сервіс Чистий Дім пропонує якісне прибирання для дому та офісу. Гарантія чистоти та задоволеності клієнтів."} />
			<meta property={"og:title"} content={"Головна | Чистий Дім - Професійний клінінг сервіс"} />
			<meta property={"og:description"} content={"Професійний клінінг сервіс Чистий Дім пропонує якісне прибирання для дому та офісу. Гарантія чистоти та задоволеності клієнтів."} />
			<meta property={"og:image"} content={"https://clearvoicer.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://clearvoicer.com/img/3965825.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://clearvoicer.com/img/3965825.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://clearvoicer.com/img/3965825.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://clearvoicer.com/img/3965825.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://clearvoicer.com/img/3965825.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" background="--color-primary" quarkly-title="Hero-12">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="80px 50px 80px 50px"
					lg-width="100%"
					sm-padding="80px 25px 40px 25px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-padding="80px 25px 80px 25px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
					>
						<Link
							href="#"
							color="#e8e4e3"
							margin="0px 0px 25px 0px"
							font="normal 400 16px/1.5 --fontFamily-sans"
							letter-spacing="1px"
							text-decoration-line="initial"
						>
							Професійний клінінг сервіс
						</Link>
						<Text
							margin="0px 0px 20px 0px"
							font="normal 400 56px/1.2 --fontFamily-serifGaramond"
							sm-font="normal 700 42px/1.2 --fontFamily-sans"
							color="--light"
							lg-margin="0px 0px 35px 0px"
						>
							Чистий Дім
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						sm-flex-direction="column"
						align-items="flex-end"
					>
						<Box
							min-width="100px"
							min-height="100px"
							display="flex"
							flex-direction="column"
							width="70%"
							lg-width="60%"
							sm-width="100%"
							sm-margin="0px 0px 25px 0px"
							justify-content="flex-end"
						>
							<Text margin="0px 0px 25px 0px" font="--lead" color="#e8e4e3" padding="0px 35px 0px 0px">
							Ласкаво просимо до нашого клінінг сервісу "Чистий Дім". Ми спеціалізуємось на професійному прибиранні житлових та комерційних приміщень. Наша команда досвідчених клінерів використовує лише екологічно чисті засоби для прибирання, що забезпечує не лише чистоту, але й безпеку вашого простору.
							</Text>
							<Link href="/contact-us" color="#e8e4e3">
								Контакти
							</Link>
						</Box>
					</Box>
				</Box>
				<Image
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
					src="https://clearvoicer.com/img/1.jpg"
					max-height="700px"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box border-radius="24px" margin="0px 0px 64px 0px" padding="60px 36px 60px 36px">
				<Box
					width="100%"
					display="flex"
					flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--dark"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Наші Послуги
					</Text>
				</Box>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 4%"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
			>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-primary"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						1
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Генеральне прибирання
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 30px 0px">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-primary"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						2
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Щоденне прибирання
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-primary"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						3
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Прибирання після ремонту
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-primary"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						4
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Миття вікон
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-primary"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						5
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Хімчистка меблів та килимів
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-7">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					overflow-x="hidden"
					transform="translateY(0px)"
					padding="0px 0px 100% 0px"
					width="100%"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					height="auto"
				>
					<Image
						src="https://clearvoicer.com/img/2.jpg"
						object-fit="cover"
						position="absolute"
						width="100%"
						bottom={0}
						display="block"
						top={0}
						left={0}
						right={0}
						min-height="100%"
					/>
				</Box>
			</Box>
			<Box
				flex-wrap="wrap"
				flex-direction="row"
				lg-align-items="center"
				padding="4px 4px 4px 4px"
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="50%"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
			>
				<Box width="50%" padding="12px 12px 12px 13px" display="flex">
					<Box
						width="100%"
						height="auto"
						position="relative"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						overflow-x="hidden"
						overflow-y="hidden"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
					>
						<Image
							top="auto"
							left={0}
							right={0}
							bottom="0px"
							display="block"
							object-fit="cover"
							position="absolute"
							width="100%"
							min-height="100%"
							src="https://clearvoicer.com/img/3.jpg"
						/>
					</Box>
				</Box>
				<Box padding="12px 12px 12px 12px" display="flex" width="50%">
					<Box
						transition="transform 0.2s ease-in-out 0s"
						hover-transform="translateY(-10px)"
						position="relative"
						transform="translateY(0px)"
						overflow-x="hidden"
						overflow-y="hidden"
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						width="100%"
						height="auto"
					>
						<Image
							bottom="0px"
							src="https://clearvoicer.com/img/4.jpg"
							position="absolute"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							display="block"
							width="100%"
							right={0}
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						transition="transform 0.2s ease-in-out 0s"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						width="100%"
					>
						<Image
							object-fit="cover"
							display="block"
							top="auto"
							right={0}
							bottom="0px"
							min-height="100%"
							src="https://clearvoicer.com/img/5.jpg"
							position="absolute"
							width="100%"
							left={0}
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						position="relative"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
						transform="translateY(0px)"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
					>
						<Image
							src="https://clearvoicer.com/img/6.jpg"
							position="absolute"
							display="block"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							width="100%"
							right={0}
							bottom="0px"
						/>
					</Box>
				</Box>
			</Box>
			<Box
				width="100%"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="0px 0px 20px 0px"
				display="flex"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					width="60%"
					lg-width="100%"
					md-text-align="left"
				>
					Зв’яжіться з нами сьогодні, щоб замовити послугу чи отримати консультацію. Ми готові відповісти на всі ваші запитання та підібрати оптимальний варіант послуг для вас.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});